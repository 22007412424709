.carousel-with-custom-dots {
	padding-bottom: 100px;
}

@media only screen and (max-width: 767px) {
	.carousel-with-custom-dots {
		padding-bottom: 65px;
	}
}

.react-multiple-carousel__arrow {
	z-index: 10;
}

.react-multi-carousel-item {
	/* display: flex;
    align-items: center;
    justify-content: center; */
}
